import logo from './logo.svg';
import './App.css';
import IframeComponent from './IframeComponent'
import Header from './components/Header';
import Footer from './components/Footer'

function App() {
  return (
    
   <div className='App'>
     <Header />
    <IframeComponent />
    <Footer />
   </div>
  );
}

export default App;
