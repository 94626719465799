import React from 'react';
import YachtingverseLogo from '../images/YachtingverseLogo.png';

const Header = () => {
  const addTokenFunction = () => {
    // Burada addTokenFunction fonksiyonunun ne yaptığını tanımlayın
  };

  return (
    <>
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
      />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"
      />
      <link rel="stylesheet" href="style.css" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Fredoka:wght@700&family=Poppins:wght@100;200;300;400;700&display=swap"
        rel="stylesheet"
      />
      <link rel="icon" type="image/png" href="./favicon.ico" />

      <title>YachtingVerse | Sail into the Future!</title>

      <div className="container-fluid custom-color-container">
        <div className="custom-navbar">
          <a className="custom-logo" href="./index.html">
          <img src={YachtingverseLogo} alt="Logo" width={250} />
          </a>
          <div className="custom-links">
            <a
              className="nav-item"
              href="https://whitepaper.yachtingverse.io/about-yachtingverse/yachtingverse"
            >
              White Paper
            </a>
       
            <a className="nav-item" href="https://yachtingverse.io/Contact.html">
              Become a Partner
            </a>
          </div>
          <div className="custom-links">
            <button className="custom-btn btn-spacing">App</button>
            <button className="custom-btn btn-spacing">Buy</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
