import React from 'react';
import YachtingverseLogo from '../images/YachtingverseLogo.png';

const Footer = () => {
  return (
    <footer className="footer" style={{ backgroundColor: "#0C1941", paddingTop: 50 }}>
      <div className="container">
        <div className="row footer-content">
          <div className="col-md-12 text-center">
            <img src={YachtingverseLogo} alt="Logo" width={250} />
            <p>© 2023 YachtingVerse All Rights Reserved.</p>
            <div className="col-md-12 text-center">
              <a style={{ color: "white" }} href="https://t.me/YachtingVerse_Chat">
                <i className="fab fa-telegram" />
              </a>
              <a style={{ color: "white" }} href="https://discord.com/invite/YnwAwxWgck">
                <i className="fab fa-discord" />
              </a>
              <a style={{ color: "white" }} href="https://twitter.com/YachtingVerse">
                <i className="fab fa-twitter" />
              </a>
              <a style={{ color: "white" }} href="https://www.youtube.com/channel/UCTnQyI7p26pKaHRPhXJqGDQ">
                <i className="fab fa-youtube" />
              </a>
              <a style={{ color: "white" }} href="https://medium.com/@YachtingVerse">
                <i className="fab fa-medium" />
              </a>
              <a style={{ color: "white" }} href=".https://yachtingverse.io/disclaimer/index.html">
                Term of Use
              </a>
              <a style={{ color: "white" }} href="https://yachtingverse.io/privacypolicy/index.html">
                Privacy Policy
              </a>
              <a style={{ color: "white" }} href="https://yachtingverse.io/cookie/index.html">
                Cookie Policy
              </a>
              <a style={{ color: "white" }} href="https://yachtingverse.io/disclaimer/index.html">
                Disclaimer
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
